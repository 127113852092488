"use client";

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { classNames } from "@/helpers/tailwind-class";
import { LinkGroup } from "@/shared/sidebar";

export function ProductSidebar({ linkGroup }: { linkGroup: LinkGroup }) {
  return (
    <Disclosure as="div" className="">
      {({ open }) => (
        <>
          <DisclosureButton className="flex w-full items-center rounded-lg px-3 py-2 text-sm font-medium text-white hover:bg-indigo-600/75">
            {linkGroup.icon}
            {linkGroup.name}
            <ChevronDownIcon
              className={classNames(open ? "rotate-180" : "", "ml-auto h-5 w-5 flex-none")}
              aria-hidden="true"
            />
          </DisclosureButton>
          <DisclosurePanel className="mt-2 space-y-2 rounded-md bg-slate-900 shadow-md">
            {linkGroup.sublinks.map((item) => (
              <DisclosureButton
                key={item.name}
                as="a"
                href={item.href}
                className="flex items-center gap-1 rounded-lg py-2 pl-4 pr-3 text-xs text-white hover:bg-indigo-600"
              >
                {item.icon}
                {item.name}
              </DisclosureButton>
            ))}
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
}
